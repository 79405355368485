/*breakpoint mixin start here*/
@screen1450: ~'only screen and (max-width: 1450px)';
@screen1150: ~'only screen and (max-width: 1150px)';
@screen1024: ~'only screen and (max-width: 1024px)';
@screen991: ~'only screen and (max-width: 991px)';
@screen767: ~'only screen and (max-width: 767px)';
@screen540: ~'only screen and (max-width: 540px)';
@screen375: ~'only screen and (max-width: 375px)';
@screen320: ~'only screen and (max-width: 320px)';
/*breakpoint mixin end here*/

// ColorPicker Variables
@white-color: #ffffff;
@black-color: #000000;

//global style variables
@colorPrimary: #038fde;
@secondary-color: #fa8c15;
@text-color: #545454;
@text-color-secondary: #595959;
@heading-color: #535353;
@header-text-color: #262626;
@layout-header-background: #fefefe;
@layout-header-background-dark: #003365;
// @layout-footer-background: #fffffe;
@body-background: #f5f5f5;
@nav-bar-bg: #fffffc;
@nav-bar-text-color: @text-color;
@blue: #85ceff;
@nav-dark-bg: #003366;
@nav-dark-text-color: #038fdd;
@menu-dark-bg: @nav-dark-bg;
@menu-dark-color: @nav-dark-text-color;
@menu-dark-highlight-color: @secondary-color;
@menu-dark-arrow-color: @nav-dark-text-color;
@hor-nav-text-color: #fffffd;

@border-radius-base: 4px;
@border-radius-sm: 4px;
@border-radius-lg: 10px;
@layout-header-background: #fff;
@layout-header-padding: 0 32px;
@card-actions-li-margin: 12px 24px;

// @font-size-base: 14px;
// @font-size-lg: @font-size-base + 0.1em;
// @font-size-sm: 12px;

// font-size: clamp(min, value, max);

@heading-1-size: clamp(25px, 4.6vw, 38px);
@heading-2-size: clamp(20px, 4vw, 30px);
@heading-3-size: clamp(18px, 3.8vw, 24px);
@heading-4-size: clamp(16px, 3vw, 20px);
@heading-5-size: clamp(14px, 2.5vw, 16px);

/*typography css end here*/
//*all variable list link are here*//
/**https://github.com/ant-design/ant-design/blob/master/components/style/themes/variable.less**/
